import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDLo7o4TId3q8fHVLctnoidikpVQOn8NYQ",
  authDomain: "bwbmatrimony.firebaseapp.com",
  projectId: "bwbmatrimony",
  storageBucket: "bwbmatrimony.appspot.com",
  messagingSenderId: "735041670583",
  appId: "1:735041670583:web:aff3c612eb97a64e566c32"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const storage = firebase.storage();
export const auth = firebase.auth();
export const db = firebase.firestore(); 


export default firebase;


