import React, { useEffect, useState } from "react";
import { db } from "./firebaseConfig/FirebaseConfig";
import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./authContext/AuthContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const Login = lazy(() => import("./pages/Login"));
const Profiles = lazy(() => import("./pages/Profiles"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const SavedProfiles = lazy(() => import("./pages/SavedProfiles"));
const ContactRequests = lazy(() => import("./pages/ContactRequests"));
const DeletedAccounts = lazy(() => import("./pages/DeletedAccounts"));
const SupportTickets = lazy(() => import("./pages/SupportTickets"));

const App = () => {
  const { user } = useAuth();
  const [profiles, setProfiles] = useState([]);
  const [deletedAccounts, setDeletedAccounts] = useState([]);
  const [savedProfiles, setSavedProfiles] = useState([]);
  const [contactRequests, setContactRequests] = useState([]);
  const [supportTickets, setSupportTickets] = useState([]);
  const [supportTicketsPending, setSupportTicketsPending] = useState([]);
  const [supportTicketsResolved, setSupportTicketsResolved] = useState([]);
  const [activeSubscription, setActiveSubscription] = useState([]);
  const [inactiveSubscriptions, setInactiveSubscriptions] = useState([]);
  const [verifiedProfiles, setVerifiedProfiles] = useState([]);
  const [notVerifiedProfiles, setNotVerifiedProfiles] = useState([]);

  useEffect(() => {
    const unSubscribeProfiles = db
      .collection("users")
      .onSnapshot((querySnapshot) => {
        const profilesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProfiles(profilesData);
        const activeSubscriptions = profilesData.filter(
          (profile) =>
            profile.subscriptionExpiry &&
            new Date(profile.subscriptionExpiry) > new Date()
        );

        setActiveSubscription(activeSubscriptions);

        const now = new Date();
        const inactiveSubs = profilesData.filter(
          (profile) =>
            (profile.subscriptionExpiry &&
              new Date(profile.subscriptionExpiry) <= now) ||
            profile.subscriptionExpiry == null
        );
        setInactiveSubscriptions(inactiveSubs);

        const verifiedProfiles = profilesData.filter(
          (profile) => profile.verified && profile.verified === true
        );
        setVerifiedProfiles(verifiedProfiles);

        const notVerifiedProfiles = profilesData.filter(
          (profile) => !profile.verified && profile.verified === false
        );
        setNotVerifiedProfiles(notVerifiedProfiles);
      });

    const unSubscribeDeletedProfiles = db
      .collection("deleted_accounts")
      .onSnapshot((querySnapshot) => {
        const deletedAccounts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDeletedAccounts(deletedAccounts);
      });

    const unSubscribeSupportTickets = db
      .collection("contactForms")
      .onSnapshot((querySnapshot) => {
        const supportTickets = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSupportTickets(supportTickets);

        const supportTicketsPending = supportTickets.filter(
          (profile) => profile.status && profile.status === "Pending"
        );
        setSupportTicketsPending(supportTicketsPending);

        const supportTicketsResolved = supportTickets.filter(
          (profile) => profile.status && profile.status === "Resolved"
        );

        setSupportTicketsResolved(supportTicketsResolved);
      });

    const unSubscribeSavedProfiles = db
      .collection("savedProfiles")
      .onSnapshot(async (querySnapshot) => {
        const savedProfiles = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setSavedProfiles(savedProfiles);
      });

    const unSubscribeContactRequests = db
      .collection("contactRequests")
      .onSnapshot((querySnapshot) => {
        const contactRequests = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setContactRequests(contactRequests);
      });

    return () => {
      unSubscribeProfiles();
      unSubscribeDeletedProfiles();
      unSubscribeSupportTickets();
      unSubscribeSavedProfiles();
      unSubscribeContactRequests();
    };
  }, []);

  return (
    <Router>
      <Suspense
        fallback={
          <div className="flone-preloader-wrapper">
            <div className="flone-preloader">
              <span></span>
              <span></span>
            </div>
          </div>
        }
      >
        <Routes>
          <Route
            path={process.env.PUBLIC_URL + "/profiles"}
            element={
              user ? <Profiles profiles={profiles} /> : <Navigate to="/login" />
            }
          />
          <Route
            path={process.env.PUBLIC_URL + "/saved-profiles"}
            element={
              user ? (
                <SavedProfiles
                  savedProfiles={savedProfiles}
                  profiles={profiles}
                />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path={process.env.PUBLIC_URL + "/contact-requests"}
            element={
              user ? (
                <ContactRequests
                  contactRequests={contactRequests}
                  profiles={profiles}
                />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path={process.env.PUBLIC_URL + "/deleted-accounts"}
            element={
              user ? (
                <DeletedAccounts deletedAccounts={deletedAccounts} />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path={process.env.PUBLIC_URL + "/support-tickets"}
            element={
              user ? (
                <SupportTickets
                  supportTicketsPending={supportTicketsPending}
                  supportTicketsResolved={supportTicketsResolved}
                  supportTickets={supportTickets}
                  profiles={profiles}
                />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path={process.env.PUBLIC_URL + "/"}
            element={
              user ? (
                <Dashboard
                  profiles={profiles}
                  deletedAccounts={deletedAccounts}
                  savedProfiles={savedProfiles}
                  contactRequests={contactRequests}
                  supportTicketsPending={supportTicketsPending}
                  supportTicketsResolved={supportTicketsResolved}
                  activeSubscription={activeSubscription}
                  inactiveSubscriptions={inactiveSubscriptions}
                  verifiedProfiles={verifiedProfiles}
                  notVerifiedProfiles={notVerifiedProfiles}
                />
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          <Route path={process.env.PUBLIC_URL + "/login"} element={<Login />} />

          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
