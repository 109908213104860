import React, { createContext, useContext, useState, useEffect } from "react";
import { auth, db } from "../firebaseConfig/FirebaseConfig";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const userDocRef = db.collection("users").doc(user.uid);
          const unsubscribeUserData = userDocRef.onSnapshot((doc) => {
            if (doc.exists && doc.data().userType === "admin") {
              setUser(user);
            } else {
              alert("Access Denied! Only admin can access");
              setUser(null);
            }
          });

          return () => unsubscribeUserData();
        } catch (error) {
          alert("Access Denied! Only admin can access");
          setUser(null);
        } finally {
          setLoading(false);
        }
      } else {
        console.log("no user");
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const logout = async () => {
    try {
      await auth.signOut();
      setUser(null);
    } catch (error) {
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ user, logout, loading }}>
      {loading && (
        <div className="flone-preloader-wrapper">
          <div className="flone-preloader">
            <span></span>
            <span></span>
          </div>
        </div>
      )}
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
